.wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media only screen and (min-width: 540px) {
        max-width: 640px;
    }
    @media only screen and (min-width: 720px) {
        margin: 0 auto;
    }
    @media only screen and (min-width: 960px) {
        width: 94%;
    }
    @media only screen and (min-width: 1124px) {
        width: 96%;
        display: block;
    }

    .action {
        text-align: right;

        .button {
            margin-left: auto;
            margin-right: 0;
        }
    }
}
