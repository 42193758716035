.wrapper {
    cursor: pointer;
    width: 100%;
    height: 94px;
    border-radius: 0.375rem;
    background: #fff;
    border: 2px dashed #7f7f7f;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: all 250ms ease-in-out;

    &.dragging {
        border-color: #00600f;

        .drag {
            opacity: 1;
        }
    }

    input {
        overflow: hidden;
        height: 0;
        width: 0;
    }

    img {
        max-height: 80px;
        width: auto;
    }

    .content {
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .drag {
        align-items: center;
        background-color: #e3e3e3;
        border-radius: 0.375rem;
        color: #388e3c;
        display: flex;
        font-size: 1.25rem;
        font-weight: 600;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0.125rem;
        left: 0.125rem;
        right: 0.125rem;
        bottom: 0.125rem;
        transition: all 250ms ease-in-out;
    }
}
