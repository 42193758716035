@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
    background: #f5f5f5;
    min-width: 375px;
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    text-decoration: none;
}

.carousel-root {
    padding-bottom: 40px;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow:before {
    border-left: 16px solid #fff;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
}

.carousel .slide {
    background: white !important;
}

.carousel .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000;
    text-align: left;
    padding: 5px 7%;
}

.carousel .text {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #353535;
    padding: 0px 7% 15px;
}

.carousel .control-dots {
    position: relative !important;
}

.carousel .control-dots .dot {
    background: black !important;
    opacity: 0.2 !important;
    width: 12px !important;
    height: 12px !important;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    opacity: 0.7 !important;
}

.basic-multi-select .select__control {
    width: 100% !important;
    border-radius: 6px !important;
    background: #fff !important;
    border: 1px solid #7f7f7f !important;
    margin: 5px 0 !important;
}

.custom-width-tooltip {
    max-width: 238px;
    background: #000 !important;
    border-radius: 6px !important;
}

.overlay {
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: unset !important;
}
