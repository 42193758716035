.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto 0;

    input {
        width: 95px;
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.flip {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
