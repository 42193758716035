.wrapper {
    margin: 0 auto;
    max-width: 36rem;
    width: 100%;

    h1 {
        color: #000000;
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.25rem;
        margin-bottom: 2.5rem;
    }

    h2 {
        color: #000;
        font-size: 1.25rem;
        font-weight: 700;
    }

    h3 {
        margin: 1rem 0 0.5rem;
        &:first-child {
            margin-top: 0;
        }
    }

    p {
        margin: 0 0 0.5rem;
    }

    section {
        margin-bottom: 2rem;
    }
}
