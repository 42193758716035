.container {
    background: #f2f2f2;
    border-radius: 12px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;
    margin-bottom: 30px;
    padding: 25px 30px;

    @media only screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-width: 1124px) {
        grid-template-columns: 1fr 2fr;
    }

    .costCenter {
        display: flex;
        flex-direction: column;

        .title {
            color: #000000;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.25rem;
            margin-bottom: 0.3125rem 0 1.875rem;
        }

        .actions {
            display: flex;
            justify-content: space-between;
            margin-top: auto;
        }
    }

    .bankAccount {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: 1fr;
        @media only screen and (min-width: 960px) {
            grid-template-columns: 1fr;
        }
        @media only screen and (min-width: 1124px) {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.add {
    align-items: center;
    background-color: transparent;
    border: none;
    color: #0aa0d2;
    cursor: pointer;
    display: flex;

    &:focus {
        outline: none;
    }

    svg {
        font-size: 1.75rem;
        margin-right: 0.25rem;
    }

    span {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1rem;
    }
}
