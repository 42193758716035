.description {
    margin-bottom: 24px;
}

.footer {
    display: flex;

    gap: 0.5rem;
}

// form {
//     display: grid;
//     grid-template-columns: 1fr;

//     .line {
//         display: flex;
//         gap: 12px;
//         justify-content: space-around;
//         align-items: center;

//         .lineicon {
//             color: #ffd600;
//         }
//     }
// }
