$border: 1px solid #7f7f7f;
$border-radius: 6px;

.wrapper {
    display: flex;
    flex-direction: row;

    input {
        height: 38px;
        border: $border;
        border-radius: 0;
        flex: 1;
        margin-top: 0;

        &:last-child {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }

        &:first-child {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }
    }

    .append,
    .prepend {
        box-sizing: border-box;
        height: 38px;
        line-height: 36px;
        text-align: center;
        border-radius: 0;
        border: $border;
        background: #d9d9d9;
        padding: 0 0.75rem;
    }

    :last-child {
        border-radius: $border-radius;
    }

    .append {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }

    .prepend {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }

    button.append,
    button.prepend {
        background-color: #ffffff;
        cursor: pointer;

        &:hover {
            background-color: #d9d9d9;
        }
    }
}
