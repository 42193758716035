@import '../../../../shared/ui/mixins';

.card {
    margin-bottom: 1rem;
}

.body {
    display: flex;
}

.additional {
    flex: 2;
    min-width: 24rem;
}

.extra {
    flex: 3;

    .deliveryLink {
        align-items: center;
        column-gap: 0.25rem;
        display: grid;
        grid-template-columns: auto 1fr auto auto;
        width: 100%;

        .label {
            white-space: nowrap;
        }

        .link {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.responsible {
    flex: 2;

    .info {
        display: grid;
        gap: 0.25rem 0.75rem;
        grid-template-columns: auto 1fr;
    }
}

.title {
    font-weight: 700;
    line-height: 1.25rem;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

.report {
    @include media-breakpoint-up(md) {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
    }
}
