.back {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.error {
    color: red;
}

.add {
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 10px 0 30px;

    &:focus {
        outline: none;
    }

    span {
        color: #0aa0d2;
        font-size: 1rem;
        font-weight: 400;
        margin-left: 1rem;
    }
}
