.title {
    font-size: 2rem;
    font-weight: 700;
}

.legend {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1rem;
}

.card {
    margin-bottom: 1.5rem;
}

.form {
    column-gap: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .expand {
        margin-bottom: 10px;
        grid-column: 1 / 3;
    }
}

.bottom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
