.check {
    color: #13be2a;
    margin: auto 0 auto 12px;
    font-size: 2em;
    position: relative;

    .content {
        opacity: 0;
        transition: opacity 250ms ease-in-out;

        &.showing {
            opacity: 1;
        }
    }

    .text {
        font-weight: 600;
        margin-left: 0.5rem;
    }

    .loader {
        align-items: center;
        display: flex;
        font-size: 1.5em;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        transition: opacity 250ms ease-in-out;

        &.loading {
            opacity: 1;
        }
    }
}

.faster {
    animation: fa-spin-new 900ms infinite cubic-bezier(0.445, 0.05, 0.45, 0.85);

    @keyframes fa-spin-new {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
