.modal {
    max-width: 80rem;
}

.watermark {
    margin-top: 2rem;
}

.disabled {
    opacity: 0.7;
    pointer-events: none;
}
