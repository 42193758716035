.item {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1em;
    margin-top: 1em;

    &:before {
        content: '>';
        display: inline-block;
        margin-left: 0.375rem;
        margin-right: 0.375rem;
    }

    &:first-child {
        &:before {
            display: none;
        }
    }
}
