.container {
    .form {
        display: grid;
        gap: 0.25rem 1rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        .name {
            grid-column: 1 / span 3;
        }
        .email {
            grid-column: 1 / span 2;
        }
    }

    .check {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .footer {
        display: flex;

        button:first-child {
            margin-right: 0.5rem;
        }
    }
}
