.textarea {
    border: 1px solid #7f7f7f;
    border-radius: 0.375rem;
    box-sizing: border-box;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    padding: 0.5rem 0.625rem;
    resize: vertical;
    width: 100%;
}
