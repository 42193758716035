.input {
    background: #fff;
    border: 1px solid #7f7f7f;
    border-radius: 0.375rem;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    height: 2.375rem;
    margin: 0.25rem 0;
    padding: 0.5rem 0.625rem;
    width: 100%;
}
