.container {
    width: 35vw;
    margin: 0 auto;
}

.card {
    div {
        margin-top: 42px;
    }
}

.row {
    display: flex;
    justify-content: space-between;
}
