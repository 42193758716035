.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
}

.card {
    margin-bottom: 1rem;
}

.content {
    border-width: 0;
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
}

.table {
    font-size: 0.875em;
}
