.container {
    margin-top: 1.5rem;

    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1.5rem;
}

.sidebar {
    > * + * {
        margin-top: 24px;
    }
}

.center {
    text-align: center;
}

.oferservices {
    > button + button {
        margin-top: 24px;
    }
}

.sections {
    display: flex;
    margin: 0 0 15px;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    min-height: 840px;

    .nav {
        margin-bottom: 0.625rem;

        .link {
            border-bottom: none;
            bottom: -1px;
            color: #000;
            display: inline-block;
            font-size: 1rem;
            font-weight: 400;
            line-height: 20px;
            padding: 0.375rem 0.75rem;
            position: relative;
            text-align: center;

            &.active {
                background: #fff;
                border-bottom: 3px solid #ffd600;
            }
        }
    }
}
