.nav {
    background-color: #000000;
    position: relative;
    justify-content: space-between;
    align-self: center;
    display: flex;
    z-index: 9999;
    padding: 10px 15px;

    .wrapper {
        display: block;
        max-width: 92%;
        width: 100%;
        margin: 0 auto;
        transition: all 300ms ease-in-out;

        @media only screen and (min-width: 540px) {
        }

        @media only screen and (min-width: 720px) {
            width: 94%;
            margin: 0 auto;
        }

        @media only screen and (min-width: 960px) {
            width: 96%;
        }

        @media only screen and (min-width: 1124px) {
            width: 98%;
            display: block;
            max-width: 1175px;
        }

        .navigation {
            display: flex;
            position: relative;
            justify-content: space-between;
            margin: 0 auto;
            padding: 0;
            z-index: 2;
            align-self: center;
            align-items: center;
        }
    }
}
