.column {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0.875rem;
}

.clickable {
    cursor: pointer;
}
