.form {
    column-gap: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .full {
        grid-column: 1 / 3;
    }

    .scheduling {
        align-items: flex-end;
        display: grid;
        grid-template-columns: 3fr 2fr;

        .button {
            margin-left: 1rem;
            margin-bottom: 0.75rem;
            text-align: center;
        }
    }

    .info {
        display: flex;
        flex-direction: column;

        .height {
            flex: 1;

            textarea {
                height: 100%;
            }
        }
    }
}
