.container {
    align-items: center;
    background-color: rgba(black, 0.3);
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: visible;
    z-index: 9999;
    display: none;

    &.show {
        display: flex;
    }
}
