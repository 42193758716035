.wrapper {
    align-items: center;
    border-radius: 0.375rem;
    background-color: #fff;
    border: 1px dashed #7f7f7f;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 94px;
    width: 100%;

    input {
        overflow: hidden;
        height: 0;
        width: 0;
    }

    img {
        max-height: 80px;
        width: auto;
    }

    span {
        font-size: 0.875rem;
    }
}
