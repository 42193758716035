.wrapper {
    align-items: center;
    background: #fff;
    border: 1px solid #7f7f7f;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 5px;
    overflow: hidden;
    padding: 0.125rem;

    input {
        align-self: stretch;
        border-width: 0;
        box-sizing: border-box;
        flex: 1;
        height: 2.375rem;
        min-width: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        &:focus {
            outline: none;
        }
    }

    .tag {
        background-color: #f2f2f2;
        border-radius: 0.25rem;
        height: 1.5rem;
        margin-top: 0.125rem;
        margin-left: 0.125rem;
        margin-right: 0.125rem;
        overflow: hidden;
        padding-right: 0.5rem;

        .button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            height: 1.5rem;
            line-height: 1.5rem;
            margin-right: 0.25rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;

            &:focus {
                outline: none;
            }

            &:hover {
                background-color: rgba(black, 0.1);
            }
        }

        span {
            color: #7f7f7f;
            line-height: 1.5rem;
        }
    }
}
