.modal {
    background: white;
    border-radius: 0.375rem;
    display: block;
    margin: 0 auto;
    padding: 2rem;
    width: 100%;
    overflow-y: auto;
}

.size-sm {
    max-width: 300px;
}

.size-md {
    max-width: 500px;
}

.size-lg {
    max-width: 800px;
}

// TODO @include media-breakpoint-up()

// $modal-sm:                          300px !default;
// $modal-md:                          500px !default;
// $modal-lg:                          800px !default;
// $modal-xl:                          1140px !default;
