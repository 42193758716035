.title {
    font-size: 2rem;
    line-height: 2.375rem;
    margin: 0 0 1.25rem;
    padding: 0;
}

.upload {
    color: #7f7f7f;
    font-size: 0.75rem;
    font-weight: 400;
}

.verticals {
    display: grid;
    gap: 0.625rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 2rem;
    padding: 0;

    li {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 1.25rem;
        padding-top: 1rem;
        border-radius: 6px;
        background: #fff;
        border: 1px solid #b2b2b2;
        box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
        transition: all 300ms ease-in-out;

        &:hover {
            cursor: pointer;
            background: #dcf0f6;
            border: 1px solid #0aa0d2;
            box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
        }

        &.selected {
            background: #dcf0f6;
            border: 3px solid #0aa0d2;
            box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
        }

        img {
            object-fit: contain;
            object-position: bottom bottom;
            height: 3.375;
            margin-bottom: 15px;
            width: auto;
        }

        p {
            padding: 0;
            margin: 0;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            color: #000;
        }
    }
}

.next {
    cursor: pointer;
    width: 10rem;

    &:focus {
        outline: none;
    }
}
