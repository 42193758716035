.content {
    display: flex;

    .orientation {
        width: 35%;
        .icon {
            color: #ffd600;
            margin-bottom: 0.5rem;
        }
        .title {
            color: white;
            font-size: 1.25rem;
            margin: 0.625rem 0;
        }
        .text {
            color: white;
        }
    }

    .deliverables {
        flex: 1;
    }

    .delivered {
        align-items: center;
        color: #13be2a;
        display: flex;
        font-size: 1.125em;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        .text {
            font-weight: 600;
            margin-left: 0.5rem;
        }
    }
}
