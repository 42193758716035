// @import '../SessionView.module.scss';

.detail {
    margin-bottom: 1rem;

    .header {
        display: flex;
        justify-content: space-between;
    }

    .session {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;

        .image {
            align-items: center;
            background: #e9e9e9;
            border-radius: 6px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            padding: 0.5rem;
            margin: 0.5rem auto;
            height: 4rem;
            width: 8rem;

            img {
                height: auto;
                margin: 0 auto;
                max-height: 100%;
                max-width: 100%;
            }
        }

        .client {
            font-weight: 700;
            font-size: 1.25rem;
            margin-bottom: 0.625rem;
            margin-top: 0;
        }

        .id {
            margin: 0 0 0.5rem;
        }
    }

    .createdAt {
        font-size: 0.75rem;
        margin-top: 0.25rem;
        text-align: center;
    }

    .scheduledFor {
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        .day {
            text-transform: capitalize;
        }
        .day,
        .hour {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .address {
        font-size: 1rem;
        margin-top: 1rem;
        text-align: center;
    }

    .actions {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 0.75rem;

        .button:first-child {
            margin-bottom: 1rem;
        }

        .check {
            color: #00b200;
        }

        span {
            margin-left: 0.25rem;
        }
    }
}

/*
.wrapper {
    @include card();

    display: inline-flex;
    margin: 0 0 15px;
    max-width: 100%;

    .image {
        align-items: center;
        background: #e9e9e9;
        border-radius: 6px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        padding: 10px;
        margin: 10px auto;
        height: 60px;
        width: 120px;

        img {
            width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }

    .id {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin: 5px 0;
        color: #000;
    }

    .schedule {
        color: #5f5f5f;
        font-size: 0.875rem;
        text-align: center;
        text-transform: capitalize;
    }

    .info {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        margin: 5px 0;
        text-align: left;
        color: #7f7f7f;
    }

    .icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        .cancel,
        .delete {
            align-items: center;
            color: #000;
            display: flex;
            font-size: 1rem;
            font-weight: 300;
            justify-content: space-around;
            line-height: 20px;

            svg {
                max-width: 12px !important;
                height: auto;
            }
        }

        .icon {
            color: #e50000;
            margin: 0 0.25rem;
        }
    }
}

.image_logo {
    align-items: center;
    background: #e9e9e9;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    justify-content: center;
    margin: 10px auto;
    height: 60px;
    width: 120px;

    img {
        width: 100%;
        object-fit: contain;
    }
}

.entregar {
    display: block;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    text-align: center;
    button {
        margin: 0 auto;
    }
}
*/
