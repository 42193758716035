.container {
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 24px;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
}

.label {
    width: 207.5%;
    margin-bottom: 24px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto 0;

    input {
        width: 95px;
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}
