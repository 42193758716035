.rdrCalendarWrapper {
    box-sizing: border-box;
    background: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdrDateDisplay {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.rdrDateDisplayItem {
    -webkit-box-flex: 1;
    flex: 1 1;
    width: 0;
    text-align: center;
    color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
    margin-left: 0.833em;
}

.rdrDateDisplayItem input {
    text-align: inherit;
}

.rdrDateDisplayItem input:disabled {
    cursor: default;
}

.rdrDateDisplayItemActive {
}

.rdrMonthAndYearWrapper {
    box-sizing: inherit;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.rdrMonthAndYearPickers {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.rdrMonthPicker {
}

.rdrYearPicker {
}

.rdrNextPrevButton {
    box-sizing: inherit;
    cursor: pointer;
    outline: none;
}

.rdrPprevButton {
}

.rdrNextButton {
}

.rdrMonths {
    display: -webkit-box;
    display: flex;
}

.rdrMonthsVertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
}

.rdrMonth {
    width: 27.667em;
}

.rdrWeekDays {
    display: -webkit-box;
    display: flex;
}

.rdrWeekDay {
    flex-basis: calc(100% / 7);
    box-sizing: inherit;
    text-align: center;
}

.rdrDays {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
}

.rdrDateDisplayWrapper {
}

.rdrMonthName {
}

.rdrInfiniteMonths {
    overflow: auto;
}

.rdrDateRangeWrapper {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdrDateInput {
    position: relative;
}

.rdrDateInput input {
    outline: none;
}

.rdrDateInput .rdrWarning {
    position: absolute;
    font-size: 1.6em;
    line-height: 1.6em;
    top: 0;
    right: 0.25em;
    color: #ff0000;
}

.rdrDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
}

.rdrDayNumber {
    display: block;
    position: relative;
}

.rdrDayNumber span {
    color: #1d2429;
}

.rdrDayDisabled {
    cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
    .rdrDay {
        flex-basis: 14.285% !important;
    }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    pointer-events: none;
}

.rdrInRange {
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    pointer-events: none;
}

.rdrDayHovered {
}

.rdrDayActive {
}

.rdrDateRangePickerWrapper {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdrDefinedRangesWrapper {
}

.rdrStaticRanges {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.rdrStaticRange {
    font-size: inherit;
}

.rdrStaticRangeLabel {
}

.rdrInputRanges {
}

.rdrInputRange {
    display: -webkit-box;
    display: flex;
}

.rdrInputRangeInput {
}

.rdrCalendarWrapper {
    position: relative;
}

.rdrMonthAndYearWrapper {
    display: none;
    position: absolute;
    top: 57px;
    min-width: 100%;
    background: white;
}

.rdrMonths {
    display: none;
    position: absolute;
    top: 117px;
    background: white;
    width: 100%;
}

.rdrCalendarWrapper:hover .rdrMonthAndYearWrapper,
.rdrCalendarWrapper:hover .rdrMonths {
    display: flex;
}

.rdrCalendarWrapper:hover {
    border-bottom: none;
}

.rdrDateRangeWrapper {
    border: 1px solid #7f7f7f;
    border-radius: 6px;
}

.rdrDateDisplay {
    background-color: transparent !important;
}

.rdrMonth {
    width: 100%;
    position: relative;
    background: white;
    padding: 0 !important;
}
