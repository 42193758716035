.root {
    .wrapper {
        display: block;
        width: 100%;
        margin: 0 auto;
        transition: all 300ms ease-in-out;

        // @media only screen and (min-width: 540px) {
        // }

        @media only screen and (min-width: 720px) {
            max-width: 92%;
        }

        @media only screen and (min-width: 960px) {
            max-width: 94%;
        }

        @media only screen and (min-width: 1124px) {
            max-width: 96%;
        }
    }
}
