.header {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.content {
    border-width: 0;
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1rem;

    .card {
        padding-bottom: 1rem;
    }
}

.table {
    font-size: 0.875em;
}
