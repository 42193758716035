.container {
    max-width: 100%;
}

.filter {
    display: flex;
    flex-direction: column;

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .row {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
    }
}

.cursor {
    cursor: pointer;
}
