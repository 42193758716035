.container {
    width: 50vw;
    margin: 36px auto 0;
}

.wrapper {
    background: #f2f2f2;
    border-radius: 12px;
    margin-bottom: 30px;
    padding: 15px;

    @media only screen and (min-width: 540px) {
        padding: 20px 20px;
    }

    @media only screen and (min-width: 720px) {
        padding: 20px 25px;
    }

    @media only screen and (min-width: 960px) {
        padding: 25px 30px;
    }

    @media only screen and (min-width: 1124px) {
        padding: 30px 35px;
    }

    .title {
        margin: 0 0 15px 0;
        padding: 0;
        font-size: 1rem;
    }

    .items {
        box-sizing: border-box;
        display: grid;
        gap: 12px;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr;
        list-style-type: none;
        margin: 0 0 20px;
        padding: 0;

        @media only screen and (min-width: 540px) {
            grid-template-columns: 1fr 1fr;
            gap: 14px;
        }

        @media only screen and (min-width: 720px) {
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        }

        @media only screen and (min-width: 960px) {
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }

        @media only screen and (min-width: 1124px) {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 18px;
        }
        @media only screen and (min-width: 1360px) {
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
        }

        li {
            align-items: center;
            background: #fff;
            border: 1px solid #b2b2b2;
            border-radius: 6px;
            box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 1.5rem;
            padding-bottom: 0.25rem;
            position: relative;
            transition: all 300ms ease-in-out;

            &:hover {
                cursor: pointer;
                background: #dcf0f6;
                border: 1px solid #0aa0d2;
                box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
            }

            &.selected {
                cursor: pointer;
                background: #dcf0f6;
                border: 3px solid #0aa0d2;
                box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
            }

            .tag {
                align-items: center;
                background-color: transparent;
                border: none;
                border-radius: 1rem;
                cursor: pointer;
                display: flex;
                justify-content: center;
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                height: 2rem;
                width: 2rem;

                &:hover {
                    background-color: rgba(black, 0.1);

                    svg {
                        color: #717171;
                    }
                }

                svg {
                    color: #b1b1b1;
                }
            }

            .icon {
                max-width: 50px;
                width: 100%;
                height: auto;
            }

            p {
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
            }
        }
    }
}

.row {
    display: flex;
    justify-content: space-between;
}
