.container {
    align-items: center;
    color: #13be2a;
    display: flex;
    font-size: 1.125em;
    justify-content: flex-end;
    position: relative;

    &.left {
        justify-content: flex-start;

        .loader {
            left: 0;
            right: initial;
        }

        .text {
            margin-left: 0.75rem;
            margin-right: 0;
        }
    }

    .content {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        opacity: 0;
        transition: opacity 250ms ease-in-out;

        &.showing {
            opacity: 1;
        }
    }

    .text {
        font-weight: 600;
        margin-right: 0.75rem;
    }

    .loader {
        align-items: center;
        display: flex;
        font-size: 1.5em;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0.75rem;
        bottom: 0;
        transition: opacity 250ms ease-in-out;

        &.loading {
            opacity: 1;
        }
    }
}
