.card {
    margin-bottom: 1rem;

    .header {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.08);
        display: flex;
        padding: 0.625rem 1rem;
        width: auto;

        .title {
            flex: 1;
            font-size: 1.125rem;
            font-weight: 500;
            margin: 0 1rem;
            text-transform: uppercase;
        }
    }

    .orientations {
        h4 {
            font-size: 1rem;
            margin: 0;
        }

        .info {
            align-items: center;
            column-gap: 1rem;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto;

            .icon {
                color: #ffc400;
                grid-row: 1 / span 2;
                justify-self: center;
                margin-bottom: 0.5rem;
            }

            .title {
                grid-column: 2;
                grid-row: 1;
                margin: 0;
            }

            .text {
                grid-column: 2;
                grid-row: 2;
                margin: 0;
            }
        }

        .form {
            align-items: center;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto auto;

            textarea {
                grid-column: 1 / span 2;
                grid-row: 2;
            }
        }
    }
}
