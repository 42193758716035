.card {
    padding: 40px 30px;
    border-radius: 10px;
    background: #fff;
    margin: 20px 0;
    box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
}

.form {
    display: grid;
    justify-content: space-between;
    margin-bottom: 20px;
    grid-template-columns: 1fr;
    grid-gap: 15px;

    @media only screen and (min-width: 540px) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-width: 720px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media only screen and (min-width: 1124px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.formTitle {
    line-height: 30px;
    font-size: 16px;
}

.cards {
    display: grid;
    justify-content: space-between;
    margin-bottom: 20px;
    grid-template-columns: 1fr;
    grid-gap: 15px;

    @media only screen and (min-width: 540px) {
        grid-template-columns: 1fr;
    }
    @media only screen and (min-width: 720px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media only screen and (min-width: 1124px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .item {
        height: 142px;
        border-radius: 10px;
        padding: 20px 25px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &.total {
            background: #f4f4f4;
            flex-direction: row;
            align-items: center;
            p {
                color: #000;
            }

            .number {
                font-weight: bold;
                font-size: 48px;
                line-height: 52px;
                text-align: left;
                color: #000;
                margin: 0 20px 0 0;
                padding: 0;
            }
        }
        &.agendado {
            background: #e8f7fc;
            color: #0aa0d2;
            p {
                color: #0aa0d2;
            }
            path {
                fill: #0aa0d2;
            }
        }
        &.pendente {
            background: #fcdddd;
            color: #e50000;
            p {
                color: #e50000;
            }
            path {
                fill: #e50000;
            }
        }
        &.entregue {
            background: #e2fee2;
            color: #00b200;
            p {
                color: #00b200;
            }
            path {
                fill: #00b200;
            }
        }

        .type {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            margin: 5px 0 0 0;
            padding: 0;
        }

        .items {
            display: block;
            justify-content: space-between;
            margin: 10px 0 0;
            div {
                font-size: 24px;
                line-height: 28px;
                display: inline-flex;
                line-height: 25px;
                width: 48%;
            }

            img {
                max-width: 28px;
                max-height: 28px;
                margin-right: 10px;
                height: auto;
                width: 100%;
            }
        }
    }
}

.row {
    display: flex;
    min-height: 48px;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;
    font-size: 14px;
}

.header {
    border-bottom: 3px solid #888;
    font-weight: bold;
    font-size: 16px;
}

.rowBackDark {
    background-color: #f1f1f1;
}
.rowBackLight {
    background-color: #fff;
}

.col {
    flex: 1;
    margin: 0 auto;
    padding: 0 10px;
    text-align: center;
    align-self: center;
}

.col2 {
    flex: 2;
}

.col3 {
    flex: 3;
}

.col4 {
    flex: 4;
}

.block {
    display: inline-flex;
    flex-direction: row;
}

.justifyCenter {
    justify-content: center;
}

.textLeft {
    text-align: left;
}

.smallIcon {
    max-height: 24px;

    svg {
        path {
            fill: red !important;
        }
    }

    path {
        fill: red !important;
    }
}

.product {
    max-width: 28px;
    margin: 0;
}

.tooltip {
    position: relative;
    display: inline-block;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
}
.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.click {
    cursor: pointer;
}

.center {
    text-align: center;
    margin: 0 0 10px 0;
}

.input {
    width: 100%;
    height: 38px;
    border-radius: 6px;
    background: #fff;
    padding: 8px 10px;
    box-sizing: border-box;
    border: 1px solid #7f7f7f;
}

.icon {
    svg {
        height: 25px;
        width: 25px;
    }
    &.agendada {
        color: #0aa0d2;
    }
    &.pendente {
        color: #e50000;
    }
    &.entregue {
        color: #00b200;
    }
    &.cancelada {
        color: white;
        background-color: #e50000;
    }
    &.criada {
        color: red;
    }
    &.noshow {
        color: black;
        background-color: yellow;
    }
}

.label {
    display: flex;
    width: 100%;
    margin: 10px 2%;
    flex-direction: column;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #000;

    &.long {
        width: 205%;
    }
}

.listPagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.sessionStatus {
    color: white;
    margin: 5px 20px;
    border-radius: 12px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;

    &.criada {
        background-color: #ffd600;
        color: black;
    }
    &.entregue {
        background-color: #00b200;
    }
    &.permitida {
        background-color: #0ab5d2;
    }
    &.cancelada {
        background-color: #ff5050;
    }
    &.noshow {
        background-color: grey;
    }
}
