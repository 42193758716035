.card {
    padding: 40px 30px;
    border-radius: 10px;
    background: #fff;
    margin: 20px 0;
    box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
}

.main {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 520px;
    margin: 0 auto;

    @media only screen and (min-width: 540px) {
    }

    @media only screen and (min-width: 720px) {
    }

    @media only screen and (min-width: 960px) {
    }

    @media only screen and (min-width: 1124px) {
    }
}
