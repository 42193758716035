@import '../../../../shared/ui/mixins';

.legend {
    background-color: rgba(black, 0.1);
    border-radius: 0.75rem;
    display: block;
    margin: 0.75rem auto;
    padding: 0.75rem 1.25rem;
    text-align: center;
}

.title {
    color: rgba(black, 1);
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 0.3125rem 0 1.875rem;
}

.items {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(lg) {
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
    }
}

.footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
