.container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    > h2 {
        margin: 0;
    }

    form {
        .row {
            margin-bottom: 24px;
            button {
                margin-right: auto;
            }
        }
    }
}

.rules {
    .rule + .rule {
        margin-top: 24px;
    }
}

.rule {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .edit {
        margin-right: auto;
    }
}

.card {
    display: grid;
    grid-template-columns: 7fr 1fr;
}

.upndown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-left: 36px;
    padding: 48px 0;

    > * {
        cursor: pointer;
    }
}

.row {
    display: flex;
    gap: 24px;
}

.rowbutton {
    justify-content: flex-end;
}

.grid {
    display: grid;

    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
}

.actionbutton {
    margin: auto;
}
