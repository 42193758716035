.container {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    padding-top: 1rem;

    .title {
        color: #000000;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.25rem;
        margin-bottom: 0.3125rem 0 1.875rem;
    }

    .form {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 1rem;

        @media only screen and (min-width: 720px) {
            grid-template-columns: 2fr 3fr;
            grid-gap: 1rem;
        }
    }

    .footer {
        align-items: center;
        display: flex;
        margin-top: 2rem;

        .button {
            margin-right: 2.5%;
        }
    }
}
