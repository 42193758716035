.label {
    display: inline-block;

    input {
        margin: 0;
        transform: scale(1.25);
        vertical-align: middle;
    }

    span {
        margin-left: 0.375rem;
        user-select: none;
        vertical-align: middle;
    }
}
