.container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .card {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: auto;
        max-width: calc(min(90vw, 30rem));
    }

    h1 {
        width: fit-content;
        text-align: center;
        margin: auto auto 18px auto;
    }
}

.row {
    display: flex;
    flex-direction: row;

    margin: 12px 0 0;
    gap: 6px;
}
