.service {
    align-items: flex-end;
    column-gap: 0.75rem;
    display: grid;
    grid-template-columns: 2fr 1fr;

    .serviceAdd {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 2.75rem;
        margin-bottom: 0.5625rem;
        padding: 0;
        span {
            display: inline-block;
            margin-right: 1rem;
        }
    }
}

.card {
    margin-bottom: 1rem;
}

.session {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;

    .remove {
        max-width: 4rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }
}

.add {
    align-items: center;
    background-color: transparent;
    border: none;
    color: #0aa0d2;
    cursor: pointer;
    display: flex;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    &:focus {
        outline: none;
    }

    svg {
        font-size: 1.75rem;
        margin-right: 0.25rem;
    }

    span {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1rem;
    }
}
