:global {
    $datepicker__font-family: 'Inter';
    $datepicker__selected-color: #ffd600;

    @import '~react-datepicker/src/stylesheets/datepicker.scss';

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker {
        border-radius: 0.75rem;

        .react-datepicker__header {
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
        }

        .react-datepicker__month-container {
            .react-datepicker__day {
                &--selected,
                &--in-selecting-range {
                    color: black;
                }
            }
        }
    }
}

.container {
    display: flex;

    > div:first-child {
        margin-right: 0.25em;
    }
}

.input {
    padding-left: 2.25em;
    padding-right: 2em;
}

.calendar,
.clear {
    align-items: center;
    background-color: transparent;
    border: none;
    color: rgba(black, 0.7);
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.calendar {
    left: 0;
    padding-left: 0.75em;
    padding-right: 0.75em;
    pointer-events: none;
}

.clear {
    opacity: 0;
    padding-left: 0.75em;
    padding-right: 0.75em;
    pointer-events: none;
    right: 0;
    transition: all 200ms;

    &.clearable {
        opacity: 1;
        pointer-events: all;
    }
}
