.container {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    .submit {
        grid-column: span 4;
        text-align: right;
    }
}
