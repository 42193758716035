.link {
    color: black;
    display: block;
    font-size: 1.125rem;
    padding: 0.25rem 1rem;
}

.session {
    display: grid;
    gap: 0.375rem;
    grid-template-columns: 1fr;
    position: sticky;
    text-align: center;
    top: 1rem;
    width: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        .id span {
            font-weight: bolder;
            margin-right: 0.5rem;
        }
        .badge {
            align-items: center;
            border-radius: 5rem;
            display: flex;
            font-size: 0.875rem;
            text-transform: uppercase;
        }
    }

    .address {
        font-size: 0.75rem;
    }

    .deliveredBy {
        font-size: 0.625rem;
    }

    .label {
        margin: 0;
    }

    .text {
        font-weight: 700;
        margin: 0;
    }
}

.product {
    align-items: center;
    border-radius: 1rem;
    cursor: default;
    display: flex;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    padding: 0.5rem 0.75rem;

    &:hover {
        background-color: rgba(black, 0.05);
    }

    .icon {
        margin-right: 1rem;
    }
    .file {
        flex: 1;
    }
    .copy,
    .remove {
        background-color: rgba(black, 0.13);
        border: none;
        border-radius: 0.9rem;
        cursor: pointer;
        height: 1.8rem;
        width: 1.8rem;
        margin-left: 0.5rem;

        &:hover {
            background-color: rgba(black, 0.6);
            color: white;
        }
    }
}

.photos {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(3, 1fr);

    .photo {
        position: relative;

        img {
            background-color: rgba(black, 0.1);
            display: block;
            min-height: 5rem;
            height: auto;
            width: 100%;
        }
    }
}

.pending {
    align-items: center;
    display: flex;
    flex-direction: column;
    .icon {
        color: #3f3f3f;
        font-size: 2.25rem;
    }
    .text {
        font-size: 1.25rem;
        margin-top: 2rem;
    }
}
