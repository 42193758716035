.link {
    color: black;
    display: block;
    font-size: 1.125rem;
    padding: 0.25rem 1rem;
}

.id {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;

    span {
        font-size: 1.125rem;
        font-weight: 800;
        margin-right: 0.25rem;
    }
}

.status {
    margin-top: 0.375rem;
}

.nav {
    margin-top: -0.75rem;
    margin-bottom: 0.25rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    .link {
        border-bottom: none;
        bottom: -1px;
        color: #000;
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 20px;
        padding: 0.375rem 0.75rem;
        position: relative;
        text-align: center;

        &.active {
            background: #fff;
            border-bottom: 3px solid #ffd600;
        }
    }
}

.actions {
    display: flex;
    justify-content: flex-end;
    margin-left: 0.25rem;
    margin-right: 0.75rem;

    > * {
        margin-left: 0.5rem;
    }
}

.whatsAppColumn {
    flex-direction: row;
}

.address {
    column-gap: 0.25rem;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr;

    .input {
        grid-column: 1 / 5;
    }
}
