.list {
    flex: 1;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 40px 0;

        &:after {
            content: '';
            position: absolute;
            background: #fff;
            display: block;
            height: 1px;
            width: 80vw;
            transform: translateX(-50%);
            bottom: 30px;
            left: 50%;
        }
    }

    p,
    .link {
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        display: inline-block;
        white-space: nowrap;
        margin: 0 0.75rem;
        transition: all 200ms ease-in;
        position: relative;
        opacity: 1;

        &:hover {
            opacity: 0.6;
        }

        @media (max-width: 768px) {
            padding: 20px 0;
            font-size: 1.5rem;
            z-index: 6;
        }
    }
}

.button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #ffffff;
    display: inline-block;
    font-size: 1rem;
    margin: 0 1vw;
    opacity: 1;
    position: relative;
    transition: all 200ms ease-in;
    white-space: nowrap;

    &:hover {
        opacity: 0.6;
    }

    @media (max-width: 768px) {
        padding: 20px 0;
        font-size: 1.5rem;
        z-index: 6;
        flex: 1;
    }
}

.user {
    color: white;
    margin: 0;
}
