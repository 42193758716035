.pagination {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;

    .items {
        align-items: center;
        display: flex;

        span {
            white-space: nowrap;
        }
        .select {
            margin-left: 0.5rem;
        }
    }

    .buttons {
        .button {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
        }
    }
}
