.content {
    display: block;
}

.item {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:nth-child(2n) {
        background-color: rgba(black, 0.1);
    }

    .quill {
        :global {
            .ql-toolbar {
                background-color: white;
                border-color: #7f7f7f;
                border-top-left-radius: 0.375rem;
                border-top-right-radius: 0.375rem;
            }
            .ql-container {
                background-color: white;
                border-color: #7f7f7f;
                border-bottom-left-radius: 0.375rem;
                border-bottom-right-radius: 0.375rem;
                .ql-editor {
                    min-height: 8rem;
                }
            }
        }
    }
}

.footer {
    display: flex;
    margin-top: 1rem;

    .add {
        margin-right: auto;
    }
}
