.table {
    margin: 24px 0;
    min-height: 200px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;
    font-size: 14px;
    cursor: pointer;
}

.header {
    border-bottom: 3px solid #888;
    font-weight: bold;
    font-size: 16px;
}

.rowBackDark {
    background-color: #f1f1f1;
}
.rowBackLight {
    background-color: #fff;
}

.col {
    flex: 1;
    margin: 0 auto;
    padding: 0 10px;
    text-align: center;
}

.col2 {
    flex: 2;
}

.col3 {
    flex: 3;
}

.textLeft {
    text-align: left;
}

.listPagination {
    margin: 20px 0 0;
    justify-content: center;
}

.order {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;

    svg {
        margin-right: 36px;
    }
}
