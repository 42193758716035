.button {
    border-color: transparent;
    border-radius: 100px;
    border-style: solid;
    color: black;
    cursor: pointer;
    display: inline-block;
    // min-width: 10rem;
    font-weight: 700;
    opacity: 1;
    box-sizing: border-box;
    transition: all 300ms ease-in-out;

    &:hover {
        background: black;
        color: white;
    }

    &.alert {
        background: yellow;
        color: black;
    }

    &.outline {
        background: transparent;
        border-color: #ffd600;

        &:hover {
            background: #ffd600;
            color: #000000;
        }
    }

    &.size-md {
        border-width: 2px;
        font-size: 1rem;
        // min-width: 10rem;
        padding: 0.625rem 1.25rem;
    }

    &.size-lg {
        border-width: 2px;
        font-size: 1rem;
        padding: 1rem 2.5rem;
    }

    &.variant-primary {
        $color: #ffd600;
        background: $color;

        &:hover {
            background: black;
        }

        &.outline {
            background: transparent;
            border-color: $color;

            &:hover {
                background: $color;
                color: #000000;
            }
        }
    }

    &.variant-info {
        $color: #0aa0d2;

        background: $color;
        color: white;

        &.outline {
            background: transparent;
            border-color: $color;
            color: black;

            &:hover {
                background: $color;
                color: white;
            }
        }
    }
}
