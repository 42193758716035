.button {
    background-color: #f4f4f4;
    border-radius: 50%;
    border-width: 0;
    cursor: pointer;
    font-weight: 700;

    transition: all 200ms;

    &.active {
        background-color: #ffd600;
    }

    &.size-md {
        height: 2.75rem;
        width: 2.75rem;
    }

    &.size-sm {
        height: 2rem;
        width: 2rem;
    }

    &:hover {
        background-color: rgba(black, 0.25);
    }
}
