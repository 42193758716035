.card {
    background-color: #f4f4f4;
    border-radius: 12px;
    padding: 0 24px 24px;
    margin-bottom: 24px;
}

.form,
.secondform,
.columnform,
.bankform {
    display: grid;

    column-gap: 24px;
}

.form {
    grid-template-columns: 1fr 1fr 1fr;
}

.secondform {
    grid-template-columns: 1fr 2fr;
    padding-top: 24px;
}

.columnform {
    grid-template-columns: 1fr;
}

.bank {
    background-color: #fff;
    border-radius: 12px;
    padding: 0 12px;
}

.bankform {
    grid-template-columns: 1fr 1fr;
}

.center {
    margin: auto 0;
}

.thumbnail {
    align-self: center;
    width: 76px;
    height: 76px;
    border-radius: 76px;
    background-color: #ccc;

    img {
        width: 76px;
        height: 76px;
        border-radius: 76px;
    }

    :hover {
        opacity: 0.7;
    }
}

.row {
    display: flex;
    flex-direction: row;
}

.check {
    color: #13be2a;
    margin: auto 0 auto 12px;
    font-size: 2em;
    position: relative;

    .content {
        opacity: 0;
        transition: opacity 250ms ease-in-out;

        &.showing {
            opacity: 1;
        }
    }

    .text {
        font-weight: 600;
        margin-left: 0.5rem;
    }

    .loader {
        align-items: center;
        display: flex;
        font-size: 1.5em;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        transition: opacity 250ms ease-in-out;

        &.loading {
            opacity: 1;
        }
    }
}
