.title {
    font-size: 2rem;
    line-height: 2.375rem;
    margin: 0 0 1.25rem;
    padding: 0;
}

.actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.25rem;
}
