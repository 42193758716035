.card {
    margin-bottom: 1rem;

    .providerService {
        padding-top: 2rem;
        position: relative;
        text-align: center;

        h6 {
            margin-top: 0.5rem;
        }

        .edit {
            position: absolute;
            right: 1rem;
            top: 1rem;
        }
    }
}

.container {
    min-width: 95vw;
    margin: 0 auto;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.line {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;

    @media only screen and (min-width: 1124px) {
        flex-direction: row;
        justify-content: space-around;
    }

    &.between {
        justify-content: space-between;
    }

    &.side {
        justify-content: space-between;
        flex-direction: row !important;
    }

    &.grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 10px 0;
        grid-gap: 15px;

        div,
        img,
        ul,
        li,
        p {
            box-sizing: border-box;
            overflow: hidden;
        }
    }

    &.final {
        margin: 0 1%;
        justify-content: start;
        align-items: center;
    }
}

@mixin card {
    display: flex;
    padding: 20px 25px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    flex: 1;

    .header {
        min-width: 100%;
        padding: 15px 20px;
        box-sizing: border-box;

        &.borderBottom {
            border-bottom: 1px solid #999999;
        }
    }

    .icon > div {
        height: auto;
        margin: 0 15px;
        width: 1.875rem;

        &.noMargin {
            margin: 0 10px 0 0;
        }
        &.marginzero {
            margin: 0;
        }
        &.tiny {
            margin: 0 5px;
            max-width: 12px;
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        padding: 15px 0;
        box-sizing: border-box;
        border-top: 1px solid #7f7f7f;
        border-bottom: 1px solid #7f7f7f;
    }

    .body {
        display: flex;
        flex-direction: column;
        padding: 15px 20px;
        width: 100%;
        box-sizing: border-box;

        .text {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            text-align: left;
            align-items: center;
            display: flex;
            margin: 0;
            color: #4c4c4c;
            &.big {
                font-size: 26px;
                line-height: 32px;
                font-weight: bold;
                margin-bottom: 10px;
            }
        }
    }
}

.title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin: 5px 0;
    color: #000;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.text {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    align-items: center;
    display: flex;
    margin: 0;
    color: #4c4c4c;
    &.big {
        font-size: 26px;
        line-height: 32px;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.itemIcon {
    max-width: 30px;
    width: 100%;
    height: auto;
    margin: 0 15px;
    &.noMargin {
        margin: 0 10px 0 0;
    }
    &.marginzero {
        margin: 0;
    }
    &.tiny {
        margin: 0 5px;
        max-width: 12px;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;

    @media only screen and (min-width: 720px) {
        grid-template-columns: 1fr 2fr;
    }

    @media only screen and (min-width: 960px) {
        grid-template-columns: 1fr 2fr;
    }

    @media only screen and (min-width: 1124px) {
        grid-template-columns: 1fr 3fr;
    }
}

.sideline {
    display: flex;
    flex-direction: column;
    & > div {
        flex: none; /* fix porco para código mal feito */
    }
}

.sidecolumn {
    display: flex;
    flex-direction: column;
}

.duo {
    div {
        margin: 0 0 15px;
        max-width: 100%;
        // deixa o card full para responsividade

        @media only screen and (min-width: 1124px) {
            margin: 0;

            &:first-child {
                margin: 0 15px 0 0;
            }
        }
    }
}

// MODAL

body.modal-open {
    height: 100vh;
    overflow-y: hidden;
}

.overlay {
    overflow: hidden;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
}

.modalCard {
    left: 50%;
    top: 50%;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    padding: 30px 20px;
    background: #fff;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
    box-sizing: border-box;
}

.row {
    display: flex;
    justify-content: space-between;
}

.check {
    color: #13be2a;
    margin: auto 0 auto 12px;
    font-size: 2em;
    position: relative;

    .content {
        opacity: 0;
        transition: opacity 250ms ease-in-out;

        &.showing {
            opacity: 1;
        }
    }

    .text {
        font-weight: 600;
        margin-left: 0.5rem;
    }

    .loader {
        align-items: center;
        display: flex;
        font-size: 1.5em;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        transition: opacity 250ms ease-in-out;

        &.loading {
            opacity: 1;
        }
    }
}
