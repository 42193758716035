.container {
    height: 3rem;
    position: relative;

    .close {
        background-color: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        &:focus {
            outline: none;
        }
    }
}
