.row {
    display: flex;
}

.hover:hover {
    background-color: #ededed;
}

.strip:nth-child(2n + 1) {
    background-color: #f4f4f4;
}
