.badge {
    border-radius: 0.625rem;
    color: white;
    display: inline-block;
    font-size: 0.625rem;
    font-weight: 700;
    padding: 0.25rem 0.75rem;
}

.badge-sm {
    font-weight: 600;
    padding: 0.125rem 0.25rem;
}

.badge-md {
    border-radius: 0.625rem;
    padding: 0.25rem 0.75rem;
}
