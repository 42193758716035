.input {
    display: flex;
    flex: 1;
    width: 100%;
    background: #fff;
    padding: 8px 10px;
    border: 1px solid #7f7f7f;
    box-sizing: border-box;
}

.select {
    border: 1px solid #7f7f7f;
    border-radius: 6px;
    height: 38px;
    padding: 8px 10px;
    width: 100%;
}
