.container {
    display: flex;
    position: relative;

    .info {
        flex: 1;
        align-self: flex-start;
        margin-right: 1rem;
        padding: 2.25rem;
        position: sticky;
        text-align: center;
        top: 0.25rem;
        width: 19rem;

        h1 {
            font-size: 1.25rem;
        }
        h2 {
            font-size: 1rem;
            font-weight: normal;
        }
        hr {
            width: 100%;
        }
        p {
            color: #7f7f7f;
            font-size: 0.75rem;
            margin-bottom: 0;
        }

        .address,
        .corporateName {
            margin-top: 0.25rem;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        flex: 4;
    }

    .sections {
        display: flex;
        margin: 0 0 15px;
        box-sizing: border-box;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        min-height: 840px;

        .nav {
            margin-bottom: 0.625rem;

            .link {
                border-bottom: none;
                bottom: -1px;
                color: #000;
                display: inline-block;
                font-size: 1rem;
                font-weight: 400;
                line-height: 20px;
                padding: 0.375rem 0.75rem;
                position: relative;
                text-align: center;

                &.active {
                    background: #fff;
                    border-bottom: 3px solid #ffd600;
                }
            }
        }
    }
}

.divider {
    border-top: 1px solid #bbb;
}

.icon {
    align-self: center;
    width: 76px;
    height: 76px;
    border-radius: 76px;
    background-color: #ccc;

    img {
        width: 76px;
        height: 76px;
        border-radius: 76px;
    }
}
