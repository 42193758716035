.input {
    display: flex;
    flex: 1;
    width: 100%;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    height: 38px;
    border-radius: 6px;
    background: #fff;
    padding: 8px 10px;
    border: 1px solid #7f7f7f;
    box-sizing: border-box;

    &:invalid {
        // border: 1px solid red;
    }
}
