.card {
    background: #f4f4f4;
    border-radius: 0.375rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    // margin-bottom: 10px;
    // justify-content: center;
    // align-items: center;
}
