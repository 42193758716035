.form {
    white-space: nowrap;
    h3 {
        font-size: 1.2rem;
        margin-bottom: 0;
    }

    div {
        display: grid;
        grid-template-columns: 120px 240px 120px 120px 240px;
        column-gap: 24px;
        margin: auto 0;

        input[type='checkbox'] {
            margin: 0 50px;
        }
    }
}

.center {
    text-align: center;
}

.save {
    margin-top: 24px;
    margin-right: auto;
}

.row {
    display: flex;
    flex-direction: row;

    margin-top: 24px;
}

.check {
    color: #13be2a;
    margin: auto 0 auto 12px;
    font-size: 2em;
    position: relative;

    .content {
        opacity: 0;
        transition: opacity 250ms ease-in-out;

        &.showing {
            opacity: 1;
        }
    }

    .text {
        font-weight: 600;
        margin-left: 0.5rem;
    }

    .loader {
        align-items: center;
        display: flex;
        font-size: 1.5em;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        transition: opacity 250ms ease-in-out;

        &.loading {
            opacity: 1;
        }
    }
}
