.overlay {
    overflow: hidden;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
}

.modalCard {
    left: 50%;
    top: 50%;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    padding: 30px 20px;
    background: #fff;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
    box-sizing: border-box;

    .description {
        h4 {
            font-weight: normal;
        }
    }

    .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.buttons {
    display: flex;
    flex-direction: row;

    button {
        margin: 0 15px 0 0;
    }
}

.divider {
    width: 100%;
    margin: 40px auto;
    border: 1px solid #ababab;
    border-radius: 1px;
}
