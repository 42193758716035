.watermark {
    column-gap: 1rem;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;

    .spacing {
        align-items: center;
        column-gap: 0.75rem;
        display: grid;
        grid-template-columns: auto 1fr;
        row-gap: 0.25rem;
    }
}
